import CommonMask from '@/helpers/masks';
import { CartType } from '@/models/cartModel';
import { ValidCouponResponse } from '@/models/delivery/validCoupon.response';
import { useAppSelector } from '@/redux/hooks';
import { Button } from '@mui/material';
import React from 'react';

type FooterType = {
	btnTxt?: string;
	disabled?: boolean;
	onClick?(): void;
	tot: number;
	enableBtn?: boolean;
	qtd: number;
	hiddenInfos?: boolean;
	shipping?: boolean;
	shippingTax?: number;
	coupon?: string;
	couponResponse?: ValidCouponResponse | null;
	openCoupon?: boolean;
	cart?: CartType;
	seo?: any;
};

export default function FooterBottomSheet({
	btnTxt,
	disabled,
	onClick,
	tot,
	qtd,
	enableBtn = true,
	hiddenInfos = false,
	shipping = false,
	shippingTax = 0,
	coupon,
	couponResponse,
	openCoupon,
	cart,
}: FooterType) {
	const { client } = useAppSelector((state) => state.auth);
	function calcTotPerItem(item: CartType['items'][0]) {
		let newTotOptions = 0;

		if (item.options) {
			item.options.forEach((option) => {
				if (option.value) {
					newTotOptions += option.value * option.qtd;
				}
			});
		}

		const newTot = (newTotOptions + item.unityValue) * item.qtd;
		return newTot;
	}

	function renderTot() {
		if (couponResponse) {
			if (couponResponse.conteudo.entrega_gratis) {
				return CommonMask.currency(tot.toFixed(2).toString());
			} else {
				if (couponResponse.conteudo.desconto) {
					if (couponResponse.conteudo.tipo_desconto === 'P') {
						if (couponResponse.conteudo.unid_med && couponResponse.conteudo.unid_med.length) {
							let newTot = 0;

							cart?.items.forEach((product) => {
								if (couponResponse.conteudo.unid_med.includes(product.idUnity)) {
									const calc = (calcTotPerItem(product) / 100) * couponResponse.conteudo.desconto;
									newTot += calc;
								}
							});

							return CommonMask.currency((tot + shippingTax - newTot).toFixed(2).toString());
						} else {
							const newTot = shippingTax + tot;
							const calc = (tot / 100) * couponResponse.conteudo.desconto;
							return CommonMask.currency((newTot - calc).toFixed(2).toString());
						}
					} else {
						if (couponResponse.conteudo.unid_med && couponResponse.conteudo.unid_med.length) {
							let newTot = 0;

							cart?.items.forEach((product) => {
								if (couponResponse.conteudo.unid_med.includes(product.idUnity)) {
									const calc = calcTotPerItem(product) - couponResponse.conteudo.desconto;
									newTot += calc;
								} else {
									newTot += calcTotPerItem(product);
								}
							});

							return CommonMask.currency((shippingTax - newTot).toFixed(2).toString());
						} else {
							return CommonMask.currency(
								shippingTax + tot - couponResponse.conteudo.desconto < 0 ? '0,00' : (shippingTax + tot - couponResponse.conteudo.desconto).toFixed(2).toString(),
							);
						}
					}
				}
				return CommonMask.currency((shippingTax + tot).toFixed(2).toString());
			}
		} else {
			return CommonMask.currency((shippingTax + tot).toFixed(2).toString());
		}
	}

	function renderCoupon() {
		if (couponResponse && couponResponse.sucesso) {
			if (couponResponse.conteudo.entrega_gratis) {
				return <></>;
			} else {
				if (couponResponse.conteudo.tipo_desconto === 'P') {
					return `: - ${couponResponse.conteudo.desconto}%`;
				} else {
					return `: - R$ ${CommonMask.currency(couponResponse.conteudo.desconto.toFixed(2).toString())}`;
				}
			}
		} else {
			return <></>;
		}
	}

	return (
		<footer id="footerBottomSheet">
			{!hiddenInfos && (
				<>
					{shipping ? (
						<>
							<div className="row">
								<p className="footerSubtotTxt">Subtotal</p>
								<p className="footerSubtotTxt">
									<span>R$</span> {CommonMask.currency(tot.toFixed(2).toString())}
								</p>
							</div>
							<div className="row">
								<p className="footerSubtotTxt">Taxa de entrega</p>
								<p className="footerSubtotTxt">
									{couponResponse?.conteudo.entrega_gratis ? (
										<>Frete grátis</>
									) : (
										<>
											<span>R$</span> {CommonMask.currency(shippingTax.toFixed(2).toString())}
										</>
									)}
								</p>
							</div>
							{coupon && couponResponse?.sucesso && !openCoupon && (
								<div className="row">
									<p className="footerSubtotTxt">Cupom aplicado</p>
									<p className="footerSubtotTxt">
										{coupon}
										{renderCoupon()}
									</p>
								</div>
							)}
							
							<div className="row mt-2">
								<p className="footerTotTxt">Total</p>
								<p className="footerValue">
									<span>R$</span> {renderTot()}
								</p>
							</div>
						</>
					) : (
						<div className="footerContent">
							{!client?.config_cliente?.oculta_total && (
								<>
									<div>
										
										<p className="footerTotTxt">Total</p>
										<p className="footerQtdTxt">Resultado para {qtd} itens</p>
									</div>
									<p className="footerValue">
										<span>R$</span> {CommonMask.currency(tot.toFixed(2).toString())}
									</p>
								</>
							)}
						</div>
					)}
				</>
			)}
			{enableBtn && (
				<Button id='ad_delivery' variant="contained" className="btnDefault" onClick={onClick} disabled={disabled}>
					{btnTxt}
				</Button>
			)}
		</footer>
	);
}
