import ClockIcon from '@/assets/svg/Clock';
import PhoneIcon from '@/assets/svg/Phone';
import PinIcon from '@/assets/svg/Pin';
import { usePages } from '@/contexts/pages';
import { useAppSelector } from '@/redux/hooks';
import { Button, Dialog, DialogContent, Slide } from '@mui/material';
import React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RatingComp from '@/components/Rating';
import { TransitionProps } from '@mui/material/transitions';
import { WorkingHoursModel } from '@/models/workingHoursModel';

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>,
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function InformationsPage() {
	const { client } = useAppSelector((state) => state.auth);
	const { openInformation, setOpenInformation } = usePages();

	function onDismiss() {
		setOpenInformation(false);
	}

	return (
		<Dialog fullScreen open={openInformation} onClose={onDismiss} TransitionComponent={Transition} id="informations" scroll="paper" className="customDialog" disableScrollLock>
			<Button variant="contained" className="btnDown" onClick={onDismiss}>
				<KeyboardArrowDownIcon />
			</Button>
			<DialogContent>
				<section
					className="banner"
					style={{
						backgroundImage: `url(${client?.config_cliente.img_fundo})`,
					}}>
					<h1 className="bannerName">{client?.config_cliente.titulo_mobile}</h1>
				</section>
				<section className="content">
					<div className="card">
						<ul className="list">
							<li className="listItem">
								<div className="listIcon">
									<ClockIcon />
								</div>
								<div className="listContent">
									{client?.config_cliente.horario_funcionamento && (
										<>
											<p className="listTitle">Horário de funcionamento</p>
											{client?.config_cliente.horario_funcionamento?.map((day) => {
												const dayKey = Object.keys(day);
												const realKey = dayKey[0] as keyof WorkingHoursModel;
												return (
													<p key={realKey} className="listDesc">
														{realKey + ': ' + day[realKey]}
													</p>
												);
											})}
										</>
									)}
								</div>
							</li>
							<li className="listItem">
								<div className="listIcon">
									<PinIcon />
								</div>
								<div className="listContent">
									<p className="listTitle">Endereço</p>
									<p className="listDesc">{client?.config_cliente.endereco}</p>
								</div>
							</li>
							<li className="listItem">
								<div className="listIcon">
									<PhoneIcon />
								</div>
								<div className="listContent">
									<p className="listTitle">Telefone</p>
									<p className="listDesc">{client?.config_cliente.fone}</p>
								</div>
							</li>
						</ul>
						<RatingComp pageInformation={true} />
					</div>
				</section>
			</DialogContent>
		</Dialog>
	);
}
