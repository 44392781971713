import React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button } from '@mui/material';

type HeaderType = {
  title: string;
  onDismiss(): void;
};

export default function HeaderBottomSheet({ title, onDismiss }: HeaderType) {
  return (
    <div className="headerBottomSheet">
      <Button variant="contained" className="btn" onClick={onDismiss}>
        <KeyboardArrowDownIcon />
      </Button>
      <h2 className="title">{title}</h2>
    </div>
  );
}
