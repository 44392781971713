import { MainProvider } from '@/contexts/main';
import store from '@/redux/store';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import '@/styles/globals.css';
import type { AppProps } from 'next/app';
import { Provider } from 'react-redux';
import { Work_Sans } from 'next/font/google';
import BottomNavigator from '@/layout/bottomNavigator';
import { CartProvider } from '@/contexts/cart';
import { PagesProvider } from '@/contexts/pages';
import { useRouter } from 'next/router';
import { ThemeProvider } from 'next-themes';
import { MainDeliveryProvider } from '@/contexts/delivery/main';
import { CartDeliveryProvider } from '@/contexts/delivery/cart';
import Head from 'next/head';
import { useAppSelector } from '@/redux/hooks';
import SubtleConfirmationAlert from '@/components/SubtleConfirmationAlert/subtleConfirmationAlert';

const work = Work_Sans({ subsets: ['latin'] });

function App({ Component, pageProps }: AppProps) {
	const router = useRouter();

	return (
		<ThemeProvider attribute="class">
			<Head>
				<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
			</Head>
			<Provider store={store}>
				{router.route === '/pedidos' ? (
					<PagesProvider>
						<MainDeliveryProvider>
							<CartDeliveryProvider>
								<style jsx global>{`
									* {
										font-family: ${work.style.fontFamily} !important;
									}
								`}</style>
								<Component {...pageProps} />
							</CartDeliveryProvider>
						</MainDeliveryProvider>
					</PagesProvider>
				) : (
					<PagesProvider>
						<MainProvider>
							<CartProvider>
								<style jsx global>{`
									* {
										font-family: ${work.style.fontFamily} !important;
									}
								`}</style>
								<Component {...pageProps} />
								{(router.route === '/' || router.route === '/cardapio') && <BottomNavigator />}
								<SubtleConfirmationAlert/>
							</CartProvider>
						</MainProvider>
					</PagesProvider>
				)}
			</Provider>
		</ThemeProvider>
	);
}

export default App;
