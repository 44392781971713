import React, { useEffect, useState } from 'react';
import AddIcon from '@/assets/svg/Add';
import SubIcon from '@/assets/svg/Sub';
import HeaderBottomSheet from '@/components/HeaderBottomSheet';
import NotFoundComp from '@/components/NotFound';
import { useCart } from '@/contexts/cart';
import CommonMask from '@/helpers/masks';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { Button, Dialog, DialogContent, Slide } from '@mui/material';
import { PostOrder } from '@/services/order';
import { useMain } from '@/contexts/main';
import { MakeOrderRequest } from '@/models/makeOrder.request';
import FooterBottomSheet from '@/components/FooterBottomSheet';
import { CartType } from '@/models/cartModel';
import TrashIcon from '@/assets/svg/Trash';
import { usePages } from '@/contexts/pages';
import ArrowIcon from '@/assets/svg/Arrow';
import { TransitionProps } from '@mui/material/transitions';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import ImageNotFound from '@/assets/img/ImageNotFound.png';
import ModalConfirm from '@/components/ModalConfirm';

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>,
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function CartPage() {
	const dispatch = useAppDispatch();

	const { openCart, setOpenCart, setOpenPayment, setOpenOrder, setOpenProduct } = usePages();
	const { storageLogin, setCurrentProduct } = useMain();
	const { cart, Remove, ChangeQtd } = useCart();

	const { responseLogin, loginFields, client } = useAppSelector(state => state.auth);
	const { loading, orderResponse } = useAppSelector(state => state.order);
	const { listProducts } = useAppSelector(state => state.menu);

	const [table, setTable] = useState('1');
	const [removeModal, setRemoveModal] = useState(false);
	const [toRemoveItem, setToRemoveItem] = useState<CartType['items'][0]>();
	const [relatedList, setRelatedList] = useState<CartType['items'][0]['produtos_relacionados']>([]);

	const personalData = responseLogin || storageLogin;

	function onDismiss() {
		setOpenCart(false);
	}

	const handleRemoveItem = (state: boolean) => {
		setRemoveModal(state);
	};

	async function handleOrder() {
		const formProducts: MakeOrderRequest['produtos'] = [];

		cart.items.forEach((product, index) => {
			formProducts.push({
				produto_id: product.id,
				quantidade: product.qtd,
				observacao: product.observation,
				unidade_medida_id: product.idUnity,
				opcionais: [],
			});

			if (product.options) {
				product.options.forEach(option => {
					formProducts[index].opcionais.push({
						id: option.id,
						quantidade: option.qtd,
					});
				});
			}
		});

		if (personalData) {
			await dispatch(
				PostOrder({
					comanda_id: personalData.comanda_id,
					nr_mesa: table,
					produtos: formProducts,
				}),
			);
		} else if (loginFields?.config_qrcode.pre_pago || client?.config_cliente?.comanda_sem_qrcode) {
			setOpenPayment(true);
			onDismiss();
		}
	}

	function listRelatedProducts() {
		const newRelatedList: CartType['items'][0]['produtos_relacionados'] = [];
		cart.items.forEach(item => {
			if (item.produtos_relacionados?.length) {
				item.produtos_relacionados.forEach(related => {
					let addNew = true;
					if (
						newRelatedList.some(value => {
							return value.id === related.id;
						})
					) {
						addNew = false;
					}
					if (addNew) {
						newRelatedList.push(related);
					}
				});
			}
		});
		setRelatedList(newRelatedList);
	}

	useEffect(() => {
		listRelatedProducts();
	}, [cart]);

	useEffect(() => {
		if (orderResponse && orderResponse.retorno) {
			setOpenOrder(true);
			onDismiss();
		}
	}, [orderResponse]);

	function totItem(item: CartType['items'][0]) {
		let newTot = 0;
		let newTotOptions = 0;

		if (item.options) {
			item.options.forEach(option => {
				if (option.value) {
					newTotOptions += option.value * option.qtd;
				}
			});
		}

		newTot = newTot + (newTotOptions + item.unityValue);

		return newTot;
	}

	function handleRelated(id: number) {
		listProducts?.conteudo.forEach(product => {
			if (product.id === id) {
				setCurrentProduct(product);
				setOpenProduct(true);
				return;
			}
		});
	}

	useEffect(() => {
		if (personalData && personalData.nr_mesa) {
			setTable(personalData.nr_mesa);
		}
	}, [personalData]);

	const pagination = {
		clickable: true,
		renderBullet: function (index: number, className: string) {
			return '<span class="' + className + '"></span>';
		},
	};

	return (
		<Dialog fullScreen open={openCart} onClose={onDismiss} TransitionComponent={Transition} id="cart" scroll="paper" className="customDialog" disableScrollLock>
			<HeaderBottomSheet title="Carrinho" onDismiss={onDismiss} />
			<DialogContent className="flex flex-col">
				<div className="content flex-1">
					<div className="form">
						{!loginFields?.config_qrcode.pre_pago &&
							openCart &&
							loginFields?.campos_login?.map(field => {
								if (field.name === 'nr_mesa_comanda') {
									return (
										<div className="blockSelect" key={field.name}>
											<div className="selectIcon">
												<ArrowIcon />
											</div>
											<select className="select" value={table} onChange={event => setTable(event.target.value)}>
												{field.nr_mesas?.map((item, index) => (
													<option value={item} key={index}>
														Você está na mesa: {item}
													</option>
												))}
											</select>
										</div>
									);
								}
							})}
					</div>
					{cart.items && cart.items.length > 0 ? (
						<ul className="list">
							{cart.items.map((item, index) => (
								<li className="item" key={index}>
									<div className="itemImg" style={{ backgroundImage: `url(${item.img})` }}></div>
									<div className="itemContent">
										<p className="itemPrice">
											<span>R$:</span> {CommonMask.currency((totItem(item) * item.qtd).toFixed(2).toString())}
										</p>
										<p className="itemTitle">{item.title}</p>

										<p className="itemOptions">
											({item.variable}
											{item.options.length > 0 && ', '}
											{item.options.map((option, key) => {
												return (option.qtd > 1 ? option.qtd + ' - ' : '') + option.name + (key === item.options.length - 1 ? '' : ', ');
											})}
											)
										</p>
									</div>
									<div className="itemBtns">
										<Button variant="text" className="itemBtn" onClick={() => (item.qtd > 1 ? ChangeQtd(item, 'SUB') : null)}>
											<SubIcon />
										</Button>
										<span className="itemQtd">{item.qtd}</span>
										<Button variant="text" className="itemBtn" onClick={() => ChangeQtd(item, 'SUM')}>
											<AddIcon />
										</Button>
										<Button
											variant="text"
											className="btnRemove"
											onClick={() => {
												handleRemoveItem(true), setToRemoveItem(item);
											}}>
											<TrashIcon />
										</Button>
									</div>
								</li>
							))}
						</ul>
					) : (
						<NotFoundComp txt="Nenhum produto adicionado ao carrinho!" />
					)}
				</div>
				{relatedList?.length ? (
					<section className="relatedProducts">
						<p className="relatedProductsTitle">Peça também</p>
						<div>
							<Swiper slidesPerView={'auto'} spaceBetween={16} grabCursor={true} modules={[Pagination]} pagination={pagination}>
								{relatedList.map((product, index) => (
									<SwiperSlide key={index} className="relatedItem">
										<Button
											className="relatedBtn"
											onClick={() => {
												handleRelated(product.id);
											}}>
											<div
												style={{
													backgroundImage: `url(${product.imagem ? product.imagem : ImageNotFound.src})`,
												}}
												className="relatedImg"></div>
											<p className="relatedName">{product.nome}</p>
										</Button>
									</SwiperSlide>
								))}
							</Swiper>
						</div>
					</section>
				) : (
					<></>
				)}
				<ModalConfirm
					open={removeModal}
					message="Tem certeza que deseja remover o item?"
					handler={handleRemoveItem}
					action={() => {
						toRemoveItem ? Remove(toRemoveItem) : null;
						handleRemoveItem(false);
					}}
				/>
			</DialogContent>

			<Button
				sx={{ width: '90vw', margin: 'auto', zIndex: '1', backgroundColor: `${client?.config_cliente?.cor ? '#' + client?.config_cliente?.cor + '!important' : 'black!important'}` }}
				variant="contained"
				className="btnDefault"
				onClick={onDismiss}>
				Continuar Comprando
			</Button>

			<FooterBottomSheet
				btnTxt={`Fazer o ${loginFields?.config_qrcode.pre_pago || client?.config_cliente?.comanda_sem_qrcode ? 'pagamento' : 'pedido'}`}
				tot={cart.tot}
				qtd={cart.items.length}
				disabled={cart.items.length < 1 || loading}
				onClick={handleOrder}
			/>
		</Dialog>
	);
}
