import { createSlice } from '@reduxjs/toolkit';
import { ListOrder, PostOrder } from '@/services/order';
import { MakeOrderResponse } from '@/models/makeOrder.response';
import { ListOrderResponse } from '@/models/listOrder.response';

const initialState = {
	orderResponse: <MakeOrderResponse | null>null,
	list: <ListOrderResponse | null>null,
	loading: false,
	requestError: <null | string>null,
};

const orderSlice = createSlice({
	name: 'order',
	initialState,
	reducers: {
		resetOrder: () => initialState,
		resetListOrder: state => {
			state.list = null;
		},
		resetOrderResponse: state => {
			state.orderResponse = null;
		},
	},
	extraReducers: builder => {
		builder
			.addCase(PostOrder.pending, state => {
				state.requestError = null;
				state.orderResponse = null;
				state.loading = true;
			})
			.addCase(PostOrder.fulfilled, (state, { payload }) => {
				state.loading = false;
				if (payload) {
					if (payload.retorno) {
						state.orderResponse = payload;
					} else {
						state.requestError = payload.mensagem;
					}
				}
			})
			.addCase(ListOrder.pending, state => {
				state.requestError = null;
				state.loading = true;
			})
			.addCase(ListOrder.fulfilled, (state, { payload }) => {
				state.loading = false;
				if (payload) {
					if (payload.retorno) {
						state.list = payload;
					} else {
						state.requestError = payload.mensagem;
					}
				}
			});
	},
});

export const { resetOrder, resetListOrder, resetOrderResponse } = orderSlice.actions;
export default orderSlice.reducer;
