import { createAsyncThunk } from '@reduxjs/toolkit';
import api from './api';
import { MakePaymentRequest } from '@/models/makePayment.request';
import { MakePaymentResponse } from '@/models/makePayment.response';
import { StatusTransitionResponse } from '@/models/statusTransition.response';

export const PostPayment = createAsyncThunk(
  'payment/PostPayment',
  async (request: MakePaymentRequest) => {
    const response = await api
      .post(`/efetuar-pagamento`, request)
      .then((r): MakePaymentResponse => r.data)
      .catch((error) => {
        return error.response.data;
      });
    return response;
  }
);

export const GetStatusTransition = createAsyncThunk(
  'payment/GetStatusTransition',
  async (codeTrasition: number) => {
    const url = `/consultar-transacao/${codeTrasition}`;
    const response = await api
      .get(url)
      .then((r): StatusTransitionResponse => r.data)
      .catch((error) => {
        return error.response.data;
      });
    return response;
  }
);
