import { configureStore } from '@reduxjs/toolkit';
import home from './reducers/home';
import auth from './reducers/auth';
import authFidelity from './reducers/fidelity/auth';
import registerFidelity from './reducers/fidelity/register';
import punctuationFidelity from './reducers/fidelity/punctuation';
import locationFidelity from './reducers/fidelity/location';
import menu from './reducers/menu';
import alert from './reducers/alert';
import search from './reducers/search';
import order from './reducers/order';
import payment from './reducers/payment';
import success from './reducers/success';
import links from './reducers/links';
import reservation from './reducers/reservation';
import authDelivery from './reducers/delivery/auth';
import menuDelivery from './reducers/delivery/menu';
import searchDelivery from './reducers/delivery/search';
import userDelivery from './reducers/delivery/user';
import orderDelivery from './reducers/delivery/order';
import paymentDelivery from './reducers/delivery/payment';
import colaboratorFidelity from './reducers/fidelity/colaborators';
import events from './reducers/events';
import delivery from './reducers/delivery/delivery';
import pos from './reducers/pos';
import autoatendimento from './reducers/autoatendimento';

const store = configureStore({
	reducer: {
		auth,
		alert,
		home,
		menu,
		search,
		order,
		payment,
		delivery,
		events,
		success,
		links,
		reservation,
		authDelivery,
		menuDelivery,
		searchDelivery,
		userDelivery,
		orderDelivery,
		paymentDelivery,
		authFidelity,
		registerFidelity,
		colaboratorFidelity,
		punctuationFidelity,
		locationFidelity,
		pos,
		autoatendimento,
	},
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
