import { MessageObjectModel } from '@/models/messageObject.model';
import { PosCartModel } from '@/models/posCartModel';
import { PosDepartamento, PosLoginResponse, PosProduto, PosRestaurantes } from '@/models/posLogin.response';
import { PosSaleResponse } from '@/models/posSaleResponse';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	activeDepartment: {} as PosDepartamento,
	cart: [] as PosCartModel[],
	drawerProduct: {} as PosProduto,
	errorText: '',
	isLoggedIn: false,
	clientSelected: {} as PosRestaurantes,
	toCancelTransactionsNumber: 0,
	buttonReturnMulti: true,
	loginData: {} as PosLoginResponse,
	openDrawerOptional: false,
	posToCancelTransactions: [] as { cupom: string; date: string; time: string; id: string; originalCode: string; value: string }[],
	page: 0,
	posModalOnSelection: (value: string) => {},
	posModalOpen: false,
	posModalQrCode: '',
	posModalText: '',
	posModalTitle: '',
	posModalType: 'ok' as 'ok' | 'options' | 'YN' | 'number' | 'text' | 'onlyText',
	posSEModalType: 'success',
	posSEOpen: false,
	posSEText: '',
	posSETitle: '',
	posTransactionMessage: '',
	posTransactionTitle: '',
	posTransactionType: 'iddle',
	saleData: {} as PosSaleResponse,
	tempCartItem: {} as PosCartModel,
};

const eventsSlice = createSlice({
	name: 'pos',
	initialState,
	reducers: {
		addToPosCart: (state, action) => {
			state.cart.push({ ...action.payload, id: state.cart.length > 0 ? state.cart[state.cart.length - 1].id + 1 : 0 });
		},
		changeActiveDepartment: (state, action) => {
			state.activeDepartment = action.payload;
		},
		changeButtonReturnMulti: (state, action) => {
			state.buttonReturnMulti = action.payload;
		},
		changeClientSelected: (state, action) => {
			state.clientSelected = action.payload;
		},
		changePosCart: (state, action) => {
			state.cart = action.payload;
		},
		changePosDrawerOptional: (state, action) => {
			if (action?.payload) {
				state.openDrawerOptional = true;
				state.drawerProduct = action.payload;
			} else {
				state.openDrawerOptional = false;
				state.drawerProduct = initialState.drawerProduct;
			}
		},
		changePosErrorText: (state, action) => {
			state.errorText = action.payload;
		},
		addNewPosToCancelTransaction: (state, action) => {
			console.log('add')
			const toReturnArray = [...state.posToCancelTransactions];
			toReturnArray.push({ cupom: action.payload, date: '', id: '', originalCode: '', time: '', value: '' });
			state.posToCancelTransactions = toReturnArray;
		},
		changePosToCancelTransaction: (state, action) => {
			const toReturnArray = [...state.posToCancelTransactions];
			toReturnArray[toReturnArray.length - 1] = {...toReturnArray[toReturnArray.length - 1], ...action.payload};
			state.posToCancelTransactions = toReturnArray;
		},
		resetPosToCancelTransaction: state => {
			state.posToCancelTransactions = [];
		},
		removeFirstToCancelTransaction: state => {
			const toReturnArray = [...state.posToCancelTransactions];
			toReturnArray.shift();
			state.posToCancelTransactions = toReturnArray;
		},
		changePosToCancelTransactionsNumber: (state, action) => {
			state.toCancelTransactionsNumber = action.payload;
		},
		changePosLoggedIn: (state, action) => {
			state.isLoggedIn = action.payload;
		},
		changePosLoginData: (state, action) => {
			state.loginData = action.payload;
		},
		changePosModalOnSelection: (state, action) => {
			state.posModalOnSelection = action.payload;
		},
		changePosModalOpen: (state, action) => {
			state.posModalOpen = action.payload;
		},
		changePosModalQrCode: (state, action) => {
			state.posModalQrCode = action.payload;
		},
		changePosModalText: (state, action) => {
			state.posModalText = action.payload;
		},
		changePosModalTitle: (state, action) => {
			state.posModalTitle = action.payload;
		},
		changePosModalType: (state, action) => {
			state.posModalType = action.payload;
		},
		changePosPage: (state, action) => {
			state.page = action.payload;
		},
		changePosSEModalType: (state, action) => {
			state.posSEModalType = action.payload;
		},
		changePosSEOpen: (state, action) => {
			state.posSEOpen = action.payload;
		},
		changePosSEText: (state, action) => {
			state.posSEText = action.payload;
		},
		changePosSETitle: (state, action) => {
			state.posSETitle = action.payload;
		},
		changePosTransactionMessage: (state, action) => {
			state.posTransactionMessage = action.payload;
		},
		changePosTransactionTitle: (state, action) => {
			state.posTransactionTitle = action.payload;
		},
		changePosTransactionType: (state, action) => {
			state.posTransactionType = action.payload;
		},
		changeSaleRepsonse: (state, action) => {
			state.saleData = action.payload;
		},
		closePosSE: state => {
			state.posSEOpen = false;
			state.posSEText = '';
			state.posSETitle = '';
		},
		reset: () => initialState,
		resetPosModal: state => {
			state.posModalOpen = false;
			state.posModalText = '';
			state.posModalTitle = '';
			state.posModalType = 'ok';
			state.posModalOnSelection = (value: string) => {};
		},
	},
});

export const {
	addToPosCart,
	changeClientSelected,
	changeActiveDepartment,
	changeButtonReturnMulti,
	changePosCart,
	changePosDrawerOptional,
	changePosErrorText,
	changePosLoggedIn,
	addNewPosToCancelTransaction,
	changePosToCancelTransaction,
	removeFirstToCancelTransaction,
	resetPosToCancelTransaction,
	changePosLoginData,
	changePosModalOnSelection,
	changePosModalOpen,
	changePosModalQrCode,
	changePosModalText,
	changePosModalTitle,
	changePosModalType,
	changePosPage,
	changePosSEModalType,
	changePosSEOpen,
	changePosSEText,
	changePosSETitle,
	changePosTransactionMessage,
	changePosTransactionTitle,
	changePosTransactionType,
	changeSaleRepsonse,
	closePosSE,
	changePosToCancelTransactionsNumber,
	reset,
	resetPosModal,
} = eventsSlice.actions;
export default eventsSlice.reducer;
