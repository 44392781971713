import HeaderBottomSheet from '@/components/HeaderBottomSheet';
import NotFoundComp from '@/components/NotFound';
import { useMain } from '@/contexts/main';
import CommonMask from '@/helpers/masks';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import React, { useEffect, useState } from 'react';
import { ListOrder } from '@/services/order';
import FooterBottomSheet from '@/components/FooterBottomSheet';
import { usePages } from '@/contexts/pages';
import { CircularProgress, Dialog, DialogContent, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import ModalInfo from '@/components/ModalInfo';
import { resetOrderResponse } from '@/redux/reducers/order';

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>,
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function OrderPage() {
	const dispatch = useAppDispatch();
	const { storageLogin } = useMain();
	const { openOrder, setOpenOrder, setOpenPayment } = usePages();
	const { responseLogin, client } = useAppSelector(state => state.auth);
	const { loading, list, orderResponse, requestError } = useAppSelector(state => state.order);
	const personalData = responseLogin || storageLogin;
	const [showSnack, setShowSnack] = useState(false);
	const [snackMsg, setSnackMsg] = useState('');

	function onDismiss() {
		setOpenOrder(false);
	}

	async function getOrder() {
		if (personalData) {
			await dispatch(ListOrder(personalData.comanda_id));
		}
	}

	useEffect(() => {
		if ((orderResponse || personalData) && client && openOrder) {
			getOrder();
		}
	}, [openOrder]);

	useEffect(() => {
		if (requestError) {
			setSnackMsg(requestError);
			setShowSnack(true);
		}
	}, [requestError]);

	useEffect(() => {
		if (list) {
			if (orderResponse?.mensagem) {
				setSnackMsg(orderResponse.mensagem);
				setShowSnack(true);
			}
		}
	}, [list]);

	function handleModal(state: boolean) {
		dispatch(resetOrderResponse());
		setShowSnack(state);
	}

	return (
		<Dialog fullScreen open={openOrder} onClose={onDismiss} TransitionComponent={Transition} id="order" scroll="paper" className="customDialog" disableScrollLock>
			<HeaderBottomSheet title="Comanda" onDismiss={onDismiss} />

			<DialogContent>
				<div>
					{loading ? (
						<div className="loadingDefault">
							<CircularProgress className="loading" />
						</div>
					) : (
						<div className="content">
							{list?.conteudo && (
								<ul className="infos">
									{list.conteudo[0].nome && <li className="info">Nome: {list.conteudo[0].nome}</li>}
									{list.conteudo[0].nr_comanda && <li className="info">Comanda: {list.conteudo[0].nr_comanda}</li>}
									{list.conteudo[0].nr_mesa && <li className="info">Mesa: {list.conteudo[0].nr_mesa}</li>}
									{client?.config_cliente.texto_comanda && (
										<li className="info">
											<br />
											Texto: {client.config_cliente.texto_comanda}
										</li>
									)}
								</ul>
							)}
							{list?.conteudo && list.conteudo[0].produtos.length > 0 ? (
								<ul className="list">
									{list.conteudo[0].produtos.map((item, index) => (
										<li className="item" key={index}>
											<div className="itemImg" style={{ backgroundImage: `url(${item.imagem})` }}></div>
											<div className="itemContent">
												<p className="itemPrice">
													<span>R$:</span> {CommonMask.currency(item.total.toFixed(2).toString())}
												</p>
												<p className="itemTitle">{item.nome}</p>
												{item.adicionais && <p className="itemOptions">{item.adicionais.map(option => option.nome).join(', ')}</p>}
											</div>
											<p className="itemQtd">Qtd: {item.quantidade}</p>
										</li>
									))}
								</ul>
							) : (
								<NotFoundComp txt="Nenhum produto na comanda!" />
							)}
						</div>
					)}
				</div>
			</DialogContent>

			{list?.conteudo && client && client.config_cliente.pagamento_comanda && (
				<FooterBottomSheet
					btnTxt="Pagar comanda"
					tot={list?.conteudo[0].total || 0}
					qtd={list?.conteudo[0].produtos.length || 0}
					disabled={list.conteudo[0].produtos.length < 1}
					onClick={() => {
						onDismiss();
						setOpenPayment(true);
					}}
				/>
			)}
			<ModalInfo open={showSnack} handler={handleModal} message={snackMsg} />
		</Dialog>
	);
}
